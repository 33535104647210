import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "HTML5 Video for the FUll HD Web User Interface",
  "path": "/Frequently_Asked_Question/HTML5_Video_Playback_from_your_WebUI/",
  "dateChanged": "2020-06-26",
  "author": "Mike Polinowski",
  "excerpt": "After the update I noticed that that the video is no longer running smooth in my main web browser and I no longer have audio in my other browser.",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='HTML5 Video für die FUll HD Weboberfläche' dateChanged='2020-06-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Nach der Aktualisierung habe ich bemerkt, dass das Video in meinem Haupt-Webbrowser nicht mehr reibungslos läuft und ich in meinem anderen Browser kein Audio mehr habe.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/HTML5_Video_Playback_from_your_WebUI/' locationFR='/fr/Frequently_Asked_Question/HTML5_Video_Playback_from_your_WebUI/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <SEOHelmet title='FAQs - HTML5 Video for the FUll HD Web User Interface' dateChanged='2020-06-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='After the update I noticed that that the video is no longer running smooth in my main web browser and I no longer have audio in my other browser.' image='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/HTML5_Video_Playback_from_your_WebUI/' locationFR='/fr/Frequently_Asked_Question/HTML5_Video_Playback_from_your_WebUI/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "html5-video-for-the-full-hd-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#html5-video-for-the-full-hd-web-user-interface",
        "aria-label": "html5 video for the full hd web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTML5 Video for the FUll HD Web User Interface`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Starting with version 2.6(315) of the web interface, all `}<strong parentName="p">{`Full HD`}</strong>{` camera models support `}<strong parentName="p">{`HTML5 Video`}</strong>{` for `}<strong parentName="p">{`plugin-free`}</strong>{` playback of the video stream. This feature is enabled by default and can be set via the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Multimedia/Video/"
      }}>{`Multimedia/Video Menu`}</a>{`. After the update I noticed that that the video is no longer running smooth in my main web browser and I no longer have audio in my other browser.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Using the HTML5 video allows you to display the live video inside the webUI without any external plugins, e.g. ActiveX, Flash, etc. But this means that the work, that was done by those plugins is now handled by your browser. Older Desktop computers as well as mobile devices can have a hard time decoding all this data and the video stream might start to stutter.`}</p>
    <p>{`To prevent this you can limited the video stream to it's medium resolution from the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Multimedia/Video/"
      }}>{`Multimedia/Video Menu`}</a>{`. `}<strong parentName="p">{`Note`}</strong>{`: that the video displayed inside our mobile or desktop apps is not affected by this. Also the alarm videos will always be recorded in full resolution!`}</p>
    <p>{`Known Issues:`}</p>
    <ul>
      <li parentName="ul">{`The HTML5 Video script supports `}<strong parentName="li">{`Chrome`}</strong>{`, `}<strong parentName="li">{`Firefox`}</strong>{` and `}<strong parentName="li">{`Safari`}</strong>{`. `}<strong parentName="li">{`Microsoft Edge`}</strong>{` and `}<strong parentName="li">{`Microsoft Internet Explorer`}</strong>{` are not supported. But Microsoft Edge was recently replaced by Microsoft with a Chrome-based Browser. The name Edge remained, but this version `}<strong parentName="li">{`is compatible with HTML5 Video`}</strong>{` and you will have audio as well.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`Safari`}</strong>{` browser does not support audio when you activate `}<strong parentName="li">{`HTML5 Video`}</strong>{`. You can load the WebUI in Firefox or Chrome if you want to hear the audio from your camera.`}</li>
      <li parentName="ul">{`The HTML5 video stream can only be accessed via HTTP. With HTTPS, the MJPEG stream is used in medium resolution.`}</li>
    </ul>
    <h2 {...{
      "id": "webui-help",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#webui-help",
        "aria-label": "webui help permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WebUI Help`}</h2>
    <p><img parentName="p" {...{
        "src": "/en/2086ab740862a45df64b46811a1a731c/WebUI_Help_Wiki_Search.gif",
        "alt": "HTML5 Video für die FUll HD Weboberfläche"
      }}></img></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      